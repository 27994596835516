<!-- A设备管理-巡检管理-点检工单-新增工单 -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">新增工单</div>
            <el-form :inline="true" :model="formInline" label-width="110px" class="mg_top_30 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="点检类型：" class=" ">
                    <!-- <el-input v-model="formInline.planStatus" placeholder="审批人" class="width_180"></el-input> -->
                    <el-select v-model="formInline.orderType" placeholder="请选择" class="width_180">
                        <el-option v-for="item in orderTypeAr" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="接单时间：">
                    <el-date-picker type="datetime" placeholder="选择日期时间" v-model="formInline.takeTime" class="width_180"
                        clear-icon="el-input-icon"></el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="工单状态：" class=" ">
                    <el-select v-model="formInline.orderStatus" placeholder="请选择" class="width_180">
                        <el-option v-for="item in options" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="工单名称：">
                    <el-input v-model="formInline.orderName" placeholder="请输入" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="点检人：">
                    <el-input v-model="formInline.execPerson" placeholder="请输入" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="点检位置范围：">
                    <el-input v-model="formInline.orderLocScope" placeholder="请输入" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="记录人：">
                    <el-input v-model="formInline.oprPerson" placeholder="请输入" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="执行时间长度：">
                    <el-input v-model="formInline.execLenM" type="number" placeholder="请输入" class="width_180">
                    </el-input>
                </el-form-item>
                <el-form-item label="开始时间：">
                    <el-date-picker type="datetime" placeholder="选择日期时间" v-model="formInline.startTime"
                        class="width_180" clear-icon="el-input-icon"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间：">
                    <el-date-picker type="datetime" placeholder="选择日期时间" v-model="formInline.endTime" class="width_180"
                        clear-icon="el-input-icon"></el-date-picker>
                </el-form-item>
                <el-form-item label="指定结单时间：">
                    <el-date-picker type="datetime" placeholder="选择日期时间" v-model="formInline.assignEndTime"
                        class="width_180" clear-icon="el-input-icon"></el-date-picker>
                </el-form-item>
                <el-form-item label="点检班组：" class=" ">
                    <el-select v-model="formInline.execGroup" placeholder="请选择" class="width_180">
                        <el-option v-for="item in inspectionGroupAr" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="备注：">
                    <el-input v-model="formInline.remarks" placeholder="请输入" class="width_180"></el-input>
                </el-form-item> -->
                <!-- <el-form-item label="名称：">
                    <el-input v-model="formInline.user" placeholder="请输入" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="巡检时间：">
                    <el-date-picker type="datetime" placeholder="2021-02-04 14:51" v-model="formInline.user"
                        class="width_180"></el-date-picker>
                </el-form-item>
                <el-form-item label="巡检人：">
                    <el-input v-model="formInline.user" placeholder="试用用户" class="width_180"></el-input>
                </el-form-item> -->
                <el-form-item label="是否异常：" class=" ">
                    <el-radio-group v-model="formInline.isAbnormal" class="width_180 text_align_left">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否结单逾期：" class=" ">
                    <el-radio-group v-model="formInline.isEndOverdue" class="width_180 text_align_left">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>

            </el-form>
            <el-form :model="formInline" label-width="110px" class="flex flex_wrap " style="width: 65%;">
                <el-form-item label="备注：">
                    <!-- <el-input v-model="formInline.remarks" placeholder="请输入" class="width_180" readonly></el-input> -->
                    <el-input v-model="formInline.remarks" class="width_1100" type="textarea" :rows="3" readonly>
                    </el-input>
                </el-form-item>
                <el-form-item label="逾期原因：">
                    <el-input v-model="formInline.overdueReason" class="width_1100" type="textarea" :rows="3">
                    </el-input>
                </el-form-item>
            </el-form>
            <div class="box_head1 width_100">设备信息</div>
            <div class="flex flex_column width_100 pdl30 pdr30">
                <!-- <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_140 mg_top_20"
                    style=" background: #03CABE;border: none;" @click="changeDevice">选择设备</el-button> -->
                <div class="flex justify_start" style="width: 65%;">
                    <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_140 mg_top_20"
                        style=" background: #03CABE;border: none;">点检记录</el-button>
                    <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_140 mg_top_20"
                        style=" background: #03CABE;border: none;">点检内容</el-button>
                    <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_140 mg_top_20"
                        style=" background: #03CABE;border: none;">点检设备</el-button>
                </div>
                <div class="height_270">
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" class="mg_top_10 "
                        @selection-change="handleSelectionChange" height="100%" border style="width: 100%;">
                        <el-table-column prop="name" label="巡检项目" width=""></el-table-column>
                        <el-table-column prop="name" label="合计项" width=""></el-table-column>
                        <el-table-column prop="name" label="漏检数" width=""></el-table-column>
                        <el-table-column prop="name" label="异常数" width=""></el-table-column>
                        <el-table-column prop="name" label="设备编号" width=""></el-table-column>
                        <el-table-column prop="name" label="设备名称" width=""></el-table-column>
                        <el-table-column prop="name" label="规格型号" width=""></el-table-column>
                        <el-table-column label="操作"  width="">
                            <!-- <template slot-scope="scope">
                        <el-button type="text" class="color_009BFD">
                            <span style="border-bottom: 1px solid #009BFD;">详细</span>
                        </el-button>
                    </template> -->
                        </el-table-column>
                    </el-table>
                </div>

            </div>
            <!-- table -->
            <!-- <div class="flex justify_between align_center" style="width: 65%;">
                <div class="color_808080">当前1-10 总记录：123,456</div>
                <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
            </div> -->

            <div class="flex mg_top_30 mg_bottom_30">
                <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>



    </div>
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'

    import {
        inspectionGroupQuery, //点检班组筛选
        inspectionOrderTypeQuery //点检工单类型
    } from "@/api/api_deviceManagement";
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio1: 1,
                checked: false,
                options: [],
                tableData: [{
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-04',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1517 弄'
                }, {
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1519 弄'
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1516 弄'
                }, {
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1519 弄'
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1516 弄'
                }],
                formInline: {
                    user: '',
                    region: ''
                },
                input2: '',


                userID: '',
                inspectionGroupAr: [],
                orderTypeAr: []
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            }
            this.inspectionGroupQuery();
            this.inspectionOrderTypeQuery();
        },
        methods: {
            // 点检班组
            async inspectionGroupQuery() {
                const res = await inspectionGroupQuery();
                this.inspectionGroupAr = res.data;
            },
            // 点检工单类型 
            async inspectionOrderTypeQuery() {
                var param = {
                    userID: this.userID
                }
                const res = await inspectionOrderTypeQuery(param);
                this.orderTypeAr = res.rows;
            },
            sbumit() {
                this.$router.go(-1);
            },
            colsePage() {
                this.$router.go(-1);
            },

            //table选择
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            changeDevice() {
                this.equipmentLedgerOrganizationSelect();
                this.dialogTableVisible = true;
            },
        }

    }
</script>
<style scoped>

</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        background: #03CABE;
        color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-upload {
        width: 100%;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }
</style>